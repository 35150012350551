import React from 'react';
import { Outlet } from 'react-router-dom';
import creatorclasses from './AssistantCreator.module.scss';

import EditTab from '../chat/personal-assistant/EditTab';

const AssistantEditor: React.FC = () => {
    return (
        <div className={creatorclasses['creator-wrapper']}>
            <EditTab />
            <Outlet />
        </div>
    );
};

export default AssistantEditor;
