import React from 'react';

import AnnotationWrapper from './AnnotationWrapper';

const ManageAsistantsOverview: React.FC = () => {
    return (
        <AnnotationWrapper alt="Manage assistants screenshot" src="Manage_assistant.jpg">
            <p>
                The assistants you create are available in your <b>sidebar</b> and on the <b>“Manage assistants”</b>{' '}
                page.
            </p>
            <br />
            <li>
                <h3>Personal assistants</h3>
                <p>
                    Manage (show/hide from sidebar, start a chat, pin/unpin, share, edit, delete) assistants using
                    actions icon near your assistant on the page.
                </p>
            </li>
            <li>
                <h3>Shared assistants</h3>
                <p>
                    In this section, you can find shared assistants. You can start a chat, pin/unpin assistants,
                    show/hide them from the sidebar, and view the settings for each assistant.
                </p>
            </li>
            <li>
                <h3>Shared assistant indicator</h3>
                <p>The green share icon next to the assistant means that this assistant has been shared with you.</p>
            </li>
            <li>
                <h3>Sidebar</h3>
                <ul>
                    <li>
                        It is also possible to manage assistants by clicking the three dots for quick actions in the
                        sidebar.
                    </li>
                </ul>
            </li>
        </AnnotationWrapper>
    );
};

export default ManageAsistantsOverview;
