import React from 'react';
import { Icon } from '@iconify/react';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlSwitch from '@shoelace-style/shoelace/dist/react/switch';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlDialog from '@shoelace-style/shoelace/dist/react/dialog';
import SlTextarea from '@shoelace-style/shoelace/dist/react/textarea';

import { IAssistant } from '../../libs/models/Assistants';
import { RootState } from '../../redux/app/store';
import { useAppSelector, useAssistantByIdSelector } from '../../redux/app/hooks';
import { useAssistants, useChat, useGoPage } from '../../libs/hooks';
import InternalIcon from '../global/InternalIcon';
import { Constants } from '../../Constants';
import { allModels, creativityOptions } from '../utils/GPTModels';
import { getIsShared } from './helper';
import Preferences from '../../redux/features/app/Preferences';

import tooltipstyles from '../global/tooltip.module.scss';
import buttonStyles from '../global/buttons.module.scss';
import dialogStyles from '../global/dialogs.module.scss';
import classes from './AssistantActions.module.scss';

interface AssistantActionsProps {
    assistantId: string;
    isSidebar?: boolean;
    closePopOver?: () => void;
    preventCloseOnMouseLeave?: () => void;
    RecentlyChangedLabel?: React.FC;
}

const AssistantActions: React.FC<AssistantActionsProps> = ({
    assistantId,
    isSidebar,
    closePopOver = () => {},
    preventCloseOnMouseLeave = () => {},
    RecentlyChangedLabel,
}) => {
    const { goEditAssistant } = useGoPage();
    const { deleteAssistantAsync, shareAssistantAsync, unShareAssistantAsync } = useAssistants('AssistantManagement');
    const chat = useChat();

    const {
        preferences: { pinnedAssistants },
        activeUserInfo,
    } = useAppSelector((state: RootState) => state.app);
    const assistant: IAssistant = useAssistantByIdSelector(assistantId) ?? {};

    const isPinnedAssistantsLimitReached = pinnedAssistants.length >= Constants.PINNED_ASSISTANTS_LIMIT;

    const [isSharingDisabled, setIsSharingDisabled] = React.useState(false);
    const [isDeleteDisabled, setIsDeleteDisabled] = React.useState(false);

    const [shareDialogOpen, setShareDialogOpen] = React.useState(false);
    const [descriptionForSharing, setDescriptionForSharing] = React.useState(assistant.description || '');
    const [settingsDialogOpen, setSettingsDialogOpen] = React.useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

    const isShared = getIsShared(assistant);
    const isCreator = assistant?.creatorUserId === activeUserInfo?.id;

    const preferencesInstance = Preferences.getInstance();
    const isActive = preferencesInstance.isActive(assistantId);
    const isPinned = preferencesInstance.isPinned(assistantId);

    const handleAssistantActiveness = () => {
        preferencesInstance.toggleAssistantActiveness(assistantId);
        closePopOver();
    };

    const createChat = () => {
        if (!isActive) {
            preferencesInstance.toggleAssistantActiveness(assistantId);
        }

        chat.createChat(
            assistant.title,
            assistant.systemDescription,
            assistant.serviceId,
            assistant.responseTemperature,
            true,
            '',
            assistant.id,
        ).catch(console.log);
        closePopOver();
    };

    const handlePin = () => {
        if (isPinnedAssistantsLimitReached && !isPinned) return;
        preferencesInstance.toggleAssistantPin(assistantId);
        closePopOver();
    };

    const openSharingDialog = () => {
        preventCloseOnMouseLeave();
        setShareDialogOpen(true);
    };
    const closeSharingDialog = () => {
        setShareDialogOpen(false);
        setTimeout(closePopOver, 100);
    };

    const handleSharing = () => {
        setIsSharingDisabled(true);
        (isShared ? unShareAssistantAsync(assistantId) : shareAssistantAsync(assistantId, descriptionForSharing))
            .catch(console.log)
            .finally(() => {
                setIsSharingDisabled(false);
            });
        closeSharingDialog();
    };

    const handleEdit = () => {
        goEditAssistant(assistantId);
    };

    const showAssistantSettings = () => {
        preventCloseOnMouseLeave();
        setSettingsDialogOpen(true);
    };

    const closeAssistantSettings = () => {
        setSettingsDialogOpen(false);
        setTimeout(closePopOver, 100);
    };

    const showDeleteDialog = () => {
        preventCloseOnMouseLeave();
        setDeleteDialogOpen(true);
    };
    const closeDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setTimeout(closePopOver, 100);
    };
    const handleDelete = () => {
        setIsDeleteDisabled(false);
        deleteAssistantAsync(assistantId)
            .catch(console.log)
            .finally(() => {
                setIsDeleteDisabled(false);
                Preferences.getInstance().excludeDeletedAssistant(assistantId);
            });
        closeDeleteDialog();
    };

    const StartChat: React.FC = () => (
        <SlTooltip content={'Start chat'} className={tooltipstyles['sapience-tooltip']}>
            <SlButton variant="text" className={classes['action-button']} onClick={createChat}>
                <InternalIcon icon="add-chat-plus" width={20} height={20} style={{ paddingLeft: '2px' }} />
            </SlButton>
        </SlTooltip>
    );

    const PinAssistantButton: React.FC = () => (
        <SlButton
            onClick={handlePin}
            variant="text"
            className={classes['action-button']}
            // disabled={!isActive}
        >
            {isPinned ? (
                <InternalIcon icon="unpin-light" height={20} width={20} />
            ) : (
                <Icon icon="lets-icons:pin-light" height={20} width={20} />
            )}
            <span className={classes['action-button-text']}>{!isPinned ? 'Pin' : 'Unpin'}</span>
        </SlButton>
    );
    const PinAssistant: React.FC = () => {
        let content = isPinned ? 'Unpin assistant' : 'Pin assistant';
        if (!isActive) {
            content = `Assistant inactive. You can pin assistant if it's active.`;
        } else if (isPinnedAssistantsLimitReached && !isPinned) {
            content = `You can pin up to ${Constants.PINNED_ASSISTANTS_LIMIT} assistants. Please unpin one to pin a new assistant.`;
        }
        return (
            <SlTooltip content={content} className={tooltipstyles['sapience-tooltip']}>
                <PinAssistantButton />
            </SlTooltip>
        );
    };

    const ShareAssistantButton: React.FC = () => (
        <SlButton
            onClick={openSharingDialog}
            variant="text"
            className={classes['action-button']}
            disabled={isSharingDisabled}
        >
            <SlIcon name="share" />
            <span className={classes['action-button-text']}>{isShared ? 'Stop sharing' : 'Share'}</span>
        </SlButton>
    );
    const ShareAssistant: React.FC = () => (
        <SlTooltip
            content={isShared ? 'Stop sharing assistant' : 'Share assistant'}
            className={tooltipstyles['sapience-tooltip']}
        >
            <ShareAssistantButton />
        </SlTooltip>
    );

    const EditAssistantButton: React.FC = () => (
        <SlButton onClick={handleEdit} variant="text" className={classes['action-button']}>
            <Icon icon="lets-icons:edit-light" height="20px" width="20px" />
            <span className={classes['action-button-text']}>Edit</span>
        </SlButton>
    );
    const EditAssistant: React.FC = () => (
        <SlTooltip content={'Edit assistant'} className={tooltipstyles['sapience-tooltip']}>
            <EditAssistantButton />
        </SlTooltip>
    );

    const AssistantSettingsButton: React.FC = () => (
        <SlButton onClick={showAssistantSettings} variant="text" className={classes['action-button']}>
            <SlIcon name="gear" />
            <span className={classes['action-button-text']}>Settings</span>
        </SlButton>
    );
    const AssistantSettings: React.FC = () => (
        <SlTooltip content={'Assistant settings'} className={tooltipstyles['sapience-tooltip']}>
            <AssistantSettingsButton />
        </SlTooltip>
    );

    const ToggleAssistantActivenessButton: React.FC = () => (
        <SlButton onClick={handleAssistantActiveness} variant="text" className={classes['action-button']}>
            <Icon icon={'lets-icons:view-hide-light'} height="20px" width="20px" />
            <span className={classes['action-button-text']}>{isActive ? 'Hide' : 'Show'}</span>
        </SlButton>
    );

    const DeleteAssistantButton: React.FC = () => (
        <SlButton
            variant="text"
            className={classes['action-button']}
            disabled={isDeleteDisabled}
            onClick={showDeleteDialog}
        >
            <Icon icon="lets-icons:trash-light" height="20px" width="20px" />
            <span className={classes['action-button-text']}>Delete</span>
        </SlButton>
    );
    const DeleteAssistant: React.FC = () => (
        <SlTooltip content={'Delete assistant'} className={tooltipstyles['sapience-tooltip']}>
            <DeleteAssistantButton />
        </SlTooltip>
    );

    const creativity = creativityOptions.find((o) => o.temp == assistant.responseTemperature) ?? {
        title: '',
        description: '',
    };

    return (
        <>
            {isSidebar ? (
                <div className={classes['sidebar-actions-set']}>
                    {isCreator && <ShareAssistantButton />}
                    <PinAssistant />
                    {isCreator ? <EditAssistantButton /> : <AssistantSettingsButton />}
                    <ToggleAssistantActivenessButton />
                    {isCreator && <DeleteAssistantButton />}
                </div>
            ) : (
                <>
                    <div className={classes['switch-and-title']}>
                        <SlSwitch
                            aria-label="Switch assistant"
                            checked={isActive}
                            onSlChange={handleAssistantActiveness}
                            className={classes.switch}
                        />
                        <span>{assistant.title}</span>
                        {isPinned && <Icon icon="lets-icons:pin-fill" width={18} height={18} />}
                        {RecentlyChangedLabel && <RecentlyChangedLabel />}
                    </div>
                    <div className={isPinned ? classes['actions-pinned'] : classes.actions}>
                        <StartChat />
                        <PinAssistant />
                        {isCreator && <ShareAssistant />}
                        {isCreator ? <EditAssistant /> : <AssistantSettings />}
                        {isCreator && <DeleteAssistant />}
                    </div>
                </>
            )}

            <SlDialog
                open={shareDialogOpen}
                className={`${dialogStyles.dialog} ${classes['share-assistant-dialog']}`}
                label={'Share assistant'}
                onSlAfterHide={closeSharingDialog}
            >
                <SlIcon name="share" className={classes['share-assistant-icon']} />

                {isShared ? (
                    <span>
                        <b>{assistant.title}</b> is now sharing with everyone in Itera. Do you want to stop sharing your
                        assistant?
                    </span>
                ) : (
                    <span>
                        Share <b>{assistant.title}</b> personal assistant with everyone in Itera.
                    </span>
                )}
                {!isShared && (
                    <SlTextarea
                        className={classes['share-assistant-description']}
                        label="Description"
                        help-text="Max 90 symbols"
                        maxlength={90}
                        rows={2}
                        resize="none"
                        value={descriptionForSharing}
                        onSlInput={(e) => {
                            setDescriptionForSharing((e.target as HTMLInputElement).value);
                        }}
                    />
                )}

                <SlButton
                    className={buttonStyles['secondary-small']}
                    slot="footer"
                    size="small"
                    onClick={closeSharingDialog}
                >
                    Cancel
                </SlButton>
                <SlButton className={buttonStyles['primary-small']} slot="footer" size="small" onClick={handleSharing}>
                    {isShared ? 'Stop sharing' : 'Share'}
                </SlButton>
            </SlDialog>

            <SlDialog
                open={settingsDialogOpen}
                className={dialogStyles.dialog}
                label={'Assistant settings'}
                onSlAfterHide={closeAssistantSettings}
            >
                <dl className={classes['settings-dialog-content']}>
                    <dt>Name</dt>
                    <dd>{assistant.title}</dd>
                    <dt>Prompt</dt>
                    <dd>{assistant.systemDescription}</dd>
                    <dt>Creativity</dt>
                    <dd>
                        <b>{creativity.title}</b>
                        <p>{creativity.description}</p>
                    </dd>
                    <dt>Model</dt>
                    <dd>{(allModels as Record<string, string>)[assistant.serviceId]}</dd>
                </dl>
                <SlButton
                    className={buttonStyles['secondary-small']}
                    slot="footer"
                    size="small"
                    onClick={closeAssistantSettings}
                >
                    Cancel
                </SlButton>
            </SlDialog>

            <SlDialog
                open={deleteDialogOpen}
                className={dialogStyles['dialog-warning']}
                label={'Delete assistant'}
                onSlAfterHide={closeDeleteDialog}
            >
                {`Are you sure you want to delete this assistant: ${assistant.title}?`}
                <SlButton
                    className={buttonStyles['secondary-small']}
                    slot="footer"
                    size="small"
                    onClick={closeDeleteDialog}
                >
                    Cancel
                </SlButton>
                <SlButton className={buttonStyles['primary-small']} slot="footer" size="small" onClick={handleDelete}>
                    Delete assistant
                </SlButton>
            </SlDialog>
        </>
    );
};

export default AssistantActions;
