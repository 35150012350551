import { FC, useState } from 'react';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlInput from '@shoelace-style/shoelace/dist/react/input';
import SlRadioGroup from '@shoelace-style/shoelace/dist/react/radio-group';
import SlRadioButton from '@shoelace-style/shoelace/dist/react/radio-button';
import SlOption from '@shoelace-style/shoelace/dist/react/option';
import SlSelect from '@shoelace-style/shoelace/dist/react/select';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlDialog from '@shoelace-style/shoelace/dist/react/dialog';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';

import { useChat } from '../../../libs/hooks';
import { ICreateAssistantParams } from '../../../libs/services/AssistantService';
import { cardArray, creativityOptions, GPTServiceId } from '../../utils/GPTModels';
import { Constants } from '../../../Constants';

import buttonStyles from '../../global/buttons.module.scss';
import dialogStyles from '../../global/dialogs.module.scss';
import tooltipStyles from '../../global/tooltip.module.scss';
import classes from './SettingsTab.module.scss';

const PROMPT_ABSENT_WARNING = 'Your assistant needs a prompt.';
const FILL_PROMPT_FIRST = 'To generate a name, you must first fill out the prompt.';

interface SettingsTabProps {
    goBack: (params: ICreateAssistantParams) => void;
    goNext: (params: ICreateAssistantParams) => void;
    newAssistantParams: ICreateAssistantParams;
}

const SettingsTab: FC<SettingsTabProps> = ({ goBack, goNext, newAssistantParams }) => {
    const { getWorkDescription } = useChat();

    const [title, setTitle] = useState(newAssistantParams.title);
    const [isTitleGenerating, setIsTitleGenerating] = useState(false);
    const [creativity, setCreativity] = useState(
        creativityOptions.find((c) => c.temp === newAssistantParams.responseTemperature)?.value ?? 'balanced',
    );
    const [serviceId, setServiceId] = useState(newAssistantParams.serviceId || Constants.DEFAULT_MODEL);
    const [warning, setWarning] = useState('');

    const getNewAssistantParams = () => ({
        title: title.trim(),
        responseTemperature: creativityOptions.find((c) => c.value === creativity)?.temp ?? 0.9,
        serviceId,
    });

    const closeDialog = () => {
        if (warning == PROMPT_ABSENT_WARNING || warning == FILL_PROMPT_FIRST) {
            goBack(getNewAssistantParams() as ICreateAssistantParams);
        }
        setWarning('');
    };

    const handleGenerateName = () => {
        if (isTitleGenerating) return;
        const systemDescription = newAssistantParams.systemDescription?.trim();
        if (!systemDescription) {
            setWarning(FILL_PROMPT_FIRST);
            return;
        }
        setIsTitleGenerating(true);
        getWorkDescription(`
            Give a very brief, yet descriptive, title for a chat bot with the following system prompt:
            ${systemDescription}
            Give it without any quotation marks and keep it max 30 characters long. Try not to mention that it is a chatbot.
        `)
            .then((newTitle) => {
                setTitle(newTitle.trim());
            })
            .catch((error) => {
                console.error('Error generating assistant name', error);
            })
            .finally(() => {
                setIsTitleGenerating(false);
            });
    };

    const handleBackClick = () => {
        goBack(getNewAssistantParams() as ICreateAssistantParams);
    };

    const handleContinueClick = () => {
        if (!title.trim()) {
            setWarning('Your assistant needs a name.');
        } else if (!newAssistantParams.systemDescription.trim()) {
            setWarning(PROMPT_ABSENT_WARNING);
        } else {
            goNext(getNewAssistantParams() as ICreateAssistantParams);
        }
    };

    return (
        <>
            <div className={classes['settings-form']}>
                <h2 className={classes['card-header']}>Settings</h2>
                <p className={classes['card-sub-header']}>
                    {`These are your assistant's settings. If you're unsure about making changes, 
                    no worries – everything is set up for you. Just give it a name and start using it.`}
                </p>

                <div className={classes['name-input-container']}>
                    <SlInput
                        className={classes['name-input']}
                        value={title}
                        onSlInput={(e) => {
                            const target = e.target as HTMLInputElement;
                            setTitle(target.value);
                        }}
                    >
                        <div slot="label" className={classes.label}>
                            <b>Name</b>
                            <SlTooltip
                                content="Choose a name that helps you remember what the assistant is trained to do."
                                className={tooltipStyles['sapience-tooltip']}
                            >
                                <SlIcon name="info-circle"></SlIcon>
                            </SlTooltip>
                        </div>
                    </SlInput>
                    <SlButton
                        className={buttonStyles.primary}
                        onClick={handleGenerateName}
                        disabled={isTitleGenerating}
                    >
                        <SlIcon slot="prefix" name="lightning-charge"></SlIcon>
                        Generate name
                    </SlButton>
                </div>

                <SlRadioGroup
                    value={creativity}
                    onSlChange={(e) => {
                        const target = e.target as HTMLInputElement;
                        setCreativity(target.value);
                    }}
                    className={classes['creativity-selector']}
                >
                    <div slot="label" className={classes.label}>
                        <b>Select creativity</b>
                        <SlTooltip
                            content="Adjusting creativity determines if you prefer a straightforward or imaginative response."
                            className={tooltipStyles['sapience-tooltip']}
                        >
                            <SlIcon name="info-circle"></SlIcon>
                        </SlTooltip>
                    </div>
                    <div className={classes.radio}>
                        {creativityOptions.map((o) => (
                            <SlRadioButton
                                key={o.value}
                                value={o.value}
                                checked={o.value == creativity}
                                className={classes['radio-button']}
                            >
                                <p className={classes['radio-button-title']}>{o.title}</p>
                                <p className={classes['radio-button-description']}>{o.description}</p>

                                {o.value == creativity && (
                                    <SlIcon
                                        slot="suffix"
                                        className={classes['radio-button-icon']}
                                        name="check"
                                    ></SlIcon>
                                )}
                            </SlRadioButton>
                        ))}
                    </div>
                </SlRadioGroup>

                <SlSelect
                    value={serviceId}
                    placeholder={cardArray.find((c) => c.serviceId === serviceId)?.title}
                    onSlChange={(e) => {
                        const target = e.target as HTMLInputElement;
                        setServiceId(target.value as GPTServiceId);
                    }}
                    className={classes['model-selector']}
                >
                    <div slot="label" className={classes.label}>
                        <b>Select model</b>
                        <SlTooltip
                            content="Choice of model impacts the assistance's capabilities."
                            className={tooltipStyles['sapience-tooltip']}
                        >
                            <SlIcon name="info-circle"></SlIcon>
                        </SlTooltip>
                    </div>
                    {cardArray.map((c) => (
                        <SlOption key={c.serviceId} value={c.serviceId}>
                            {c.title}
                        </SlOption>
                    ))}
                </SlSelect>

                <div className={classes['navigation-buttons']}>
                    <SlButton className={buttonStyles.secondary} onClick={handleBackClick}>
                        Back
                    </SlButton>
                    <SlButton className={buttonStyles.primary} onClick={handleContinueClick}>
                        Save & Create
                    </SlButton>
                </div>
            </div>

            <SlDialog
                open={Boolean(warning)}
                className={dialogStyles['dialog-warning']}
                label="Ooops. Something is missing."
            >
                {warning}
                <SlButton className={buttonStyles['secondary-small']} slot="footer" size="small" onClick={closeDialog}>
                    Close
                </SlButton>
            </SlDialog>
        </>
    );
};

export default SettingsTab;
