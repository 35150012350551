import React from 'react';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlDetails from '@shoelace-style/shoelace/dist/react/details';

import { useGoPage } from '../../../libs/hooks';
import StartPageOverview from './StartPageOverview';
import StartNewChatOverview from './StartNewChatOverview';
import HowToCreateAssistant from './HowToCreateAssistant';
import ChatWithAssistantOverview from './ChatWithAssistantOverview';
import ManageAsistantsOverview from './ManageAsistantsOverview';
import HelpAndSupport from './HelpAndSupport';

import classes from './FAQ.module.scss';

const faqItems = [
    {
        title: 'Start page overview',
        content: StartPageOverview,
    },
    {
        title: 'Start a new chat',
        content: StartNewChatOverview,
    },
    {
        title: 'How to create an assistant',
        content: HowToCreateAssistant,
    },
    {
        title: 'Chat with an assistant',
        content: ChatWithAssistantOverview,
    },
    {
        title: 'Manage assistants',
        content: ManageAsistantsOverview,
    },
    {
        title: 'Help & Support',
        content: HelpAndSupport,
    },
];

const FAQAccordion: React.FC = () => {
    const { handleBackClick } = useGoPage();
    const [openedAccordionItem, setOpenedAccordionItem] = React.useState(0);

    const { innerWidth } = window;
    const showBackButton = Date.now() > 1 || innerWidth < 1024 || innerWidth > 1600;

    return (
        <div className={classes.wrapper}>
            <div className={classes.container}>
                {showBackButton && (
                    <SlButton variant="text" size="medium" className={classes['back-button']} onClick={handleBackClick}>
                        <SlIcon slot="prefix" name="arrow-left"></SlIcon>
                        Back
                    </SlButton>
                )}
                <h2 className={classes.header}>FAQ</h2>
                <div className={classes.accordion}>
                    {faqItems.map((item, i) => (
                        <SlDetails
                            key={item.title}
                            summary={item.title}
                            className={classes['accordion-item']}
                            open={openedAccordionItem === i + 1}
                            onSlShow={() => {
                                setOpenedAccordionItem(i + 1);
                            }}
                        >
                            <SlIcon name="chevron-down" slot="expand-icon" />
                            <SlIcon name="chevron-left" slot="collapse-icon" />
                            <div className={classes['accordion-item-content']}>{item.content({})}</div>
                        </SlDetails>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FAQAccordion;
