import React, { FC, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useMsal } from '@azure/msal-react';
import { Icon } from '@iconify/react';
import { Field, Spinner } from '@fluentui/react-components';
import SlInputElement from '@shoelace-style/shoelace/dist/components/input/input.js';
import SlTextareaElement from '@shoelace-style/shoelace/dist/components/textarea/textarea.js';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlInput from '@shoelace-style/shoelace/dist/react/input';
import SlTextarea from '@shoelace-style/shoelace/dist/react/textarea';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';

import { useChat } from '../../../libs/hooks';
import { SpeechRecognizer } from '../../chat/controls/SpeechRecognizer';
import OnboardingModal from '../../modal/OnboardingModal';
import { AssistantTutorialBodies, AssistantTutorialImages } from '../../modal/OnboardingModalData';

import { useCreationWrapperClasses } from '../../../styles/assistantCreation/creationWrappers';
import { useAHClasses } from '../../../styles/assistantHub/assHubElements';
import inputControlClasses from '../../chat/controls/InputControlButtons.module.scss';
import tooltipStyles from '../../global/tooltip.module.scss';
import buttonStyles from '../../global/buttons.module.scss';
import classes from './AssistantDescriptionTab.module.scss';

interface PurposeTabProps {
    systemDescription: string;
    setSystemDescription: (systemDescription: string) => void;
    promptInput: string;
    setPromptInput: (promptInput: string) => void;
    goNext: () => void;
}

const AssistantDescriptionTab: FC<PurposeTabProps> = ({
    setSystemDescription,
    systemDescription,
    promptInput,
    setPromptInput,
    goNext,
}) => {
    const [enableTutorialAssistant, setEnableTutorialAssistant] = React.useState(false);
    // Check if onboarding is required.
    const [cookies, setCookie] = useCookies(['COMPLETED_ASSISTANT_TUTORIAL']);
    if (!cookies.COMPLETED_ASSISTANT_TUTORIAL && !enableTutorialAssistant) {
        setEnableTutorialAssistant(true);
    }
    const [isLoading, setLoading] = useState<boolean>(false);
    const [generatorInput, setGeneratorInput] = useState<string>('');
    const createPromptInputRef = useRef<SlInputElement>(null);
    const workDescriptionRef = useRef<SlTextareaElement>(null);
    const { getWorkDescription } = useChat('AssistantDescriptionTab');

    const { instance, inProgress } = useMsal();

    const onSpeechRecognizingInput = (value: string) => {
        setGeneratorInput(value);
    };
    const onSpeechRecognizedInput = (value: string) => {
        setGeneratorInput(value);
        createPromptInputRef.current?.focus();
    };

    const speechRecognizer = SpeechRecognizer(
        onSpeechRecognizingInput,
        onSpeechRecognizedInput,
        instance,
        inProgress,
        generatorInput,
    );

    const onGenerate = () => {
        setLoading(true);
        const prompt: string =
            "I am an expert in something, but I have amnesia. My role as an expert is very important. My job is to answer any questions people have, by offering my expertise. I don't know the details of my expertise, I only have a query to build on. When I give you the query, I want you to describe my role to me as an expert. Keep it short and to the point, and write it in one paragraph without line breaks. Again, I have woken up from a coma, and I have amnesia. How would you describe my role to me, so that I can remember again? Do this based on the following query: " +
            generatorInput;
        getWorkDescription(prompt)
            .then((response) => {
                setSystemDescription(response);
            })
            .catch((error) => {
                console.error('Error generating work description', error);
            })
            .finally(() => {
                setLoading(false);
                workDescriptionRef.current?.focus();
            });
    };

    // When the user modifies the work description field
    const onChange = (event: CustomEvent) => {
        const target = event.target as HTMLInputElement;
        setSystemDescription(target.value);
    };

    // When the user modifies the "generate description" field
    const onGenChange = (event: CustomEvent) => {
        const target = event.target as HTMLInputElement;
        setGeneratorInput(target.value);
        setPromptInput(target.value);
        if (speechRecognizer.recognizer && !speechRecognizer.isRecognizingText.current) {
            speechRecognizer.setText(target.value);
        }
    };

    const AHClasses = useAHClasses();
    const wrapperclasses = useCreationWrapperClasses();

    React.useEffect(() => {
        if (generatorInput == '') {
            setGeneratorInput(promptInput);
        }
    }, [promptInput]);

    return (
        <>
            <div>
                <div className={classes['header-wrapper']}>
                    <h2 className={classes.header}>Create a prompt</h2>
                    <SlTooltip
                        content="A prompt is an instruction to your assistant on what you want it to do for you."
                        className={tooltipStyles['sapience-tooltip']}
                    >
                        <SlIcon name="info-circle"></SlIcon>
                    </SlTooltip>
                </div>

                <p className={AHClasses.description} style={{ marginBottom: '16px', marginTop: 0 }}>
                    This is where you give instructions to your assistant. It will remember them everytime you start a
                    new chat.
                    <br></br>
                    If you&apos;re new to writing prompts, or just want to save time, we recommend using the Prompt
                    generator.
                </p>
                <Field style={{ marginBottom: '32px' }}>
                    <div className={wrapperclasses.promptGeneratorWrapper}>
                        <div style={{ display: 'flex', gap: '4px', marginBottom: '8px' }}>
                            <img src="/yellow-lightning.svg" width="24px" height="24px" />
                            <h4 style={{ fontSize: '16px' }}>Prompt generator</h4>
                        </div>
                        <p>
                            Writing prompts is an art, so let&apos;s help you get started! <br></br>Describe how you
                            want your assistant to support you, in a single sentence.
                        </p>
                        <div className={classes['generator-input-and-button']}>
                            <div style={{ position: 'relative', width: '100%' }}>
                                <div className={inputControlClasses['input-controls-container']}>
                                    {speechRecognizer.recognizer && (
                                        <SlTooltip
                                            content={
                                                (speechRecognizer.isListening ? 'Stop' : 'Start') + ' speech to text'
                                            }
                                            className={tooltipStyles['sapience-tooltip']}
                                        >
                                            <SlButton
                                                aria-label="Speech to text"
                                                variant="text"
                                                onClick={speechRecognizer.handleSpeech}
                                                className={
                                                    inputControlClasses[
                                                        speechRecognizer.isListening
                                                            ? 'text-to-speech-button'
                                                            : 'input-control-button'
                                                    ]
                                                }
                                            >
                                                <Icon
                                                    icon={
                                                        speechRecognizer.isRecognizingText.current
                                                            ? 'lets-icons:stop-light'
                                                            : 'lets-icons:mic-light'
                                                    }
                                                    width="24px"
                                                    height="24px"
                                                />
                                            </SlButton>
                                        </SlTooltip>
                                    )}
                                </div>
                                <SlInput
                                    ref={createPromptInputRef}
                                    disabled={speechRecognizer.isRecognizingText.current}
                                    autoFocus
                                    value={generatorInput}
                                    onSlInput={onGenChange}
                                    className={classes['generator-input']}
                                    placeholder="As a project manager, I need help to create documentation"
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            onGenerate();
                                        }
                                    }}
                                />
                            </div>

                            <SlButton
                                disabled={generatorInput.length !== 0 && !isLoading ? false : true}
                                className={`${buttonStyles.primary} ${classes['generate-button']}`}
                                onClick={onGenerate}
                            >
                                {isLoading ? (
                                    <div style={{ display: 'flex' }}>
                                        <Spinner size="tiny" />
                                        <span style={{ marginLeft: '8px' }}>Creating draft..</span>
                                    </div>
                                ) : (
                                    <div style={{ display: 'flex', gap: '4px' }}>
                                        <Icon icon="lets-icons:lightning-light" width={24} height={24} />
                                        <span style={{ whiteSpace: 'nowrap' }}>Generate prompt</span>
                                    </div>
                                )}
                            </SlButton>
                        </div>
                    </div>
                </Field>
                <h4 style={{ margin: '0', marginBottom: '8px' }}>Your prompt</h4>
                <p className={AHClasses.description} style={{ marginBottom: '16px' }}>
                    Tell your assistant how to help you. Edit until you&apos;re happy. Come back to make changes any
                    time.
                </p>

                <SlTextarea
                    value={systemDescription}
                    placeholder="As an expert, your role is to..."
                    disabled={isLoading}
                    onSlChange={onChange}
                    ref={workDescriptionRef}
                    style={{ marginBottom: '16px' }}
                    className={classes['system-description']}
                />
            </div>
            <SlButton className={`${buttonStyles.primary} ${classes['continue-button']}`} onClick={goNext}>
                Continue
            </SlButton>
            <OnboardingModal
                isOpen={enableTutorialAssistant}
                onClose={() => {
                    setCookie('COMPLETED_ASSISTANT_TUTORIAL', true, { maxAge: 60 * 60 * 24 * 365 * 10, path: '/' });
                    setEnableTutorialAssistant(false);
                }}
                bodies={AssistantTutorialBodies}
                images={AssistantTutorialImages}
                slideNr={0}
            />
        </>
    );
};

export default AssistantDescriptionTab;
