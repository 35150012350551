import { useState } from 'react';
import {
    Button,
    Dialog,
    DialogBody,
    DialogSurface,
    makeStyles,
    shorthands,
    mergeClasses,
} from '@fluentui/react-components';
import { useButtonClasses } from '../../styles/global/button';
import { useTutorialModalStyles } from '../../styles/tutorialModal';

interface OnboardingModalProps {
    isOpen: boolean;
    onClose: (open: boolean) => void;
    bodies: JSX.Element[];
    images: string[];
    slideNr: number;
}

const useClasses = makeStyles({
    navigationButtonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'normal',
        ...shorthands.gap('16px'),
        ...shorthands.padding('12px', '24px'),
        '@media screen and (min-width: 750px)': {
            justifyContent: 'right',
            ...shorthands.gap('8px'),
        },
    },
    navigationButton: {
        ...shorthands.flex(1),
        '@media screen and (min-width: 750px)': {
            ...shorthands.flex('none'),
        },
    },
});

const OnboardingModal: React.FC<OnboardingModalProps> = ({ isOpen, onClose, bodies, images, slideNr = 0 }) => {
    const classes = useClasses();
    const styles = useTutorialModalStyles();
    const buttonclasses = useButtonClasses();
    const [index, setIndex] = useState(slideNr);

    const dialogBody =
        images[index] != '' ? (
            <div style={{ borderBottom: '1px solid #EDEDED' }} className={styles.bodyContainer}>
                <div>{bodies[index]}</div>
                <img src={'/' + images[index]} style={{ width: '260px', height: '260px' }} alt="abstract image" />
            </div>
        ) : (
            <DialogBody>{bodies[index]}</DialogBody>
        );

    const NavigationButtons: React.FC = () => {
        const isFirst = index === 0;
        const isLast = index === bodies.length - 1;
        const goBack = () => {
            setIndex(index - 1);
        };
        const goNext = () => {
            setIndex(index + 1);
        };
        const close = () => {
            onClose(true);
        };
        return (
            <div aria-label="Actions bar" className={classes.navigationButtonsContainer}>
                {!isFirst && (
                    <Button
                        onClick={goBack}
                        className={mergeClasses(buttonclasses.secondaryButtonLarge, classes.navigationButton)}
                    >
                        Back
                    </Button>
                )}
                <Button
                    onClick={isLast ? close : goNext}
                    className={mergeClasses(buttonclasses.primaryButtonLarge, classes.navigationButton)}
                >
                    {isLast ? 'Start' : 'Continue'}
                </Button>
            </div>
        );
    };

    const dialogTitle = (
        <div style={{ marginBottom: '8px' }}>
            <div
                style={{
                    height: '20px',
                    width: 'calc(100% - 48px)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '16px',
                    margin: '16px 24px',
                }}
            >
                {bodies.map((_, i) => (
                    <div
                        key={`modalProgress${i}`}
                        style={{
                            width: `100%`,
                            height: '8px',
                            backgroundColor: index > i - 1 ? '#37E17B' : '#EDEDED',
                            borderRadius: '1px',
                        }}
                    />
                ))}
            </div>
            <div style={{ height: '1px', width: '100%', backgroundColor: 'rgba(0.2,0,0,0.1)' }} />
        </div>
    );

    return (
        <Dialog
            modalType="modal"
            open={isOpen}
            onOpenChange={(_, data) => {
                setIndex(0);
                onClose(data.open);
            }}
        >
            <DialogSurface aria-label="Tutorial" style={{ maxWidth: '730px', height: 'fit-content', padding: 0 }}>
                {dialogTitle}
                {dialogBody}
                <NavigationButtons />
            </DialogSurface>
        </Dialog>
    );
};

export default OnboardingModal;
