import * as React from 'react';
import SlSpinner from '@shoelace-style/shoelace/dist/react/spinner';

import classes from './Spinner.module.scss';

const Spinner: React.FC = () => (
    <div className={classes.spinner}>
        <SlSpinner />
    </div>
);

export default Spinner;
