import React from 'react';

import AnnotationWrapper from './AnnotationWrapper';

const ChatWithAssistantOverview: React.FC = () => {
    return (
        <AnnotationWrapper alt="Chat with an assistant screenshot" src="Chat_with_assistant.jpg">
            <li>
                Once you create an <b>assistant</b>, you can start using it immediately.
                <p>Your initial instructions will guide future interactions with the assistant.</p>
                <p>
                    To start chatting, click on an <b>assistant</b> in the sidebar and select <b>“Start a chat.”</b>
                </p>
            </li>
            <li>
                All your previous chats are available under <b>&quot;Chat history&quot;</b> in the <b>Sidebar.</b>
            </li>
            <li>
                Your assistant&apos;s documents are stored in the <b>&quot;Uploaded documents&quot;</b> tab, where you
                can easily upload, delete, or edit tags at any time.
            </li>
        </AnnotationWrapper>
    );
};

export default ChatWithAssistantOverview;
