import { FC, useState, useReducer } from 'react';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlDialog from '@shoelace-style/shoelace/dist/react/dialog';

import { useChat } from '../../../libs/hooks';
import { useAssistants } from '../../../libs/hooks/useAssistants';
import { useGoPage } from '../../../libs/hooks/useRouterNavigate';
import { AlertType } from '../../../libs/models/AlertType';
import { IAssistant } from '../../../libs/models/Assistants';
import { useAppDispatch } from '../../../redux/app/hooks';
import { addAlert } from '../../../redux/features/app/appSlice';
import AssistantDescriptionTab from './AssistantDescriptionTab';
import SettingsTab from './SettingsTab';
import { functionalTabs } from './helper';
import { Constants } from '../../../Constants';
import { ICreateAssistantParams } from '../../../libs/services/AssistantService';

import '../../global/buttons.scss';
import './creationTabs.scss';
import classes from './PACreation.module.scss';
import buttonStyles from '../../global/buttons.module.scss';
import dialogStyles from '../../global/dialogs.module.scss';

const newAssistantParamsInitialState = {
    title: '',
    systemDescription: '',
    responseTemperature: 0.5,
    serviceId: Constants.DEFAULT_MODEL,
} as ICreateAssistantParams;

const PACreation: FC = () => {
    const { createAssistantAsync } = useAssistants('PACreation');
    const { createChat } = useChat('PACreation');
    const { goStartPage } = useGoPage();
    const dispatch = useAppDispatch();

    const [promptInput, setPromptInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [newAssistantParams, setNewAssistantParams] = useReducer(
        (s: ICreateAssistantParams, a: Partial<ICreateAssistantParams>) => ({ ...s, ...a }),
        newAssistantParamsInitialState,
    );

    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const goNext = () => {
        setCurrentTabIndex(currentTabIndex + 1);
    };
    const goBack = (params: ICreateAssistantParams) => {
        setNewAssistantParams(params);
        setCurrentTabIndex(currentTabIndex - 1);
    };

    const isStepCompleted = (i: number) => {
        switch (i) {
            case 0:
                return Boolean(newAssistantParams.systemDescription.trim());
            case 1:
                const { title, responseTemperature, serviceId } = newAssistantParams;
                return [title, responseTemperature, serviceId].every(Boolean);
            default:
                return false;
        }
    };

    const setSystemDescription = (systemDescription: string) => {
        setNewAssistantParams({ systemDescription });
    };

    const openDialog = () => {
        setIsDialogOpen(true);
    };
    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    const dispatchAlert = (message: string, type: AlertType) => {
        dispatch(addAlert({ message, type, isToast: true }));
    };

    const successHandler = (a: IAssistant) => {
        dispatchAlert(`Assistant created successfully`, AlertType.Success);

        void createChat(a.title, a.systemDescription, a.serviceId, a.responseTemperature, true, '', a.id);
    };
    const errorHandler = (error: Error) => {
        console.error(error);
        dispatchAlert(error.message, AlertType.Error);
    };
    const finalHandler = () => {
        setIsLoading(false);
    };

    const createAssistant = (newAssistantParamsFromSettings: ICreateAssistantParams) => {
        if (isLoading) return;
        setIsLoading(true);
        createAssistantAsync({
            ...newAssistantParams,
            ...newAssistantParamsFromSettings,
        })
            .then(successHandler)
            .catch(errorHandler)
            .finally(finalHandler);
    };

    return (
        <div className={classes.container}>
            <div className={classes['header-container']}>
                <h2 className={classes.header}>Build your assistant</h2>

                <SlButton className={`${buttonStyles.secondary} ${classes['close-button']}`} onClick={openDialog}>
                    <SlIcon slot="prefix" name="x-lg"></SlIcon>
                    <span className={classes['close-button-label']}>Close</span>
                </SlButton>
            </div>
            <div className={classes['tabs-container']}>
                <div className={classes['tabs-wrapper']}>
                    <div className={classes['tabs-labels']}>
                        {functionalTabs.map(({ panelName, title }, i) => (
                            <div
                                key={panelName}
                                onClick={() => {
                                    setCurrentTabIndex(i);
                                }}
                                className={`${classes['tabs-labels-label']} ${
                                    i <= currentTabIndex ? classes['tabs-labels-label_completed'] : ''
                                }`}
                            >
                                {isStepCompleted(i) && (
                                    <SlIcon
                                        className={classes['tabs-labels-label-icon']}
                                        name="check-circle-fill"
                                    ></SlIcon>
                                )}
                                <b>Step {i + 1}.&nbsp;</b>
                                {title}
                            </div>
                        ))}
                    </div>

                    {/* removed shoelace SlTabGroup, SlTab, SlTabPanel because of problem with ResizeObserver */}
                    {currentTabIndex == 0 ? (
                        <AssistantDescriptionTab
                            goNext={goNext}
                            setSystemDescription={setSystemDescription}
                            systemDescription={newAssistantParams.systemDescription}
                            promptInput={promptInput}
                            setPromptInput={setPromptInput}
                        />
                    ) : (
                        <SettingsTab goBack={goBack} goNext={createAssistant} newAssistantParams={newAssistantParams} />
                    )}
                </div>
            </div>

            <SlDialog open={isDialogOpen} className={dialogStyles['dialog-warning']} label="Unsaved changes">
                By exiting assistant creation, all progress will be lost.
                <SlButton className={buttonStyles['secondary-small']} slot="footer" size="small" onClick={closeDialog}>
                    Cancel
                </SlButton>
                <SlButton className={buttonStyles['primary-small']} slot="footer" size="small" onClick={goStartPage}>
                    Exit
                </SlButton>
            </SlDialog>
        </div>
    );
};

export default PACreation;
