import { GPTServiceId } from '../../components/utils/GPTModels';
import { IAssistant } from '../models/Assistants';
import { BaseService } from './BaseService';

interface AssistantResponse {
    assistant: IAssistant;
}

export interface ICreateAssistantParams {
    title: string;
    systemDescription: string;
    description?: string;
    responseTemperature: number;
    serviceId: GPTServiceId;
    accessToken?: string;
}

export interface IEditAssistantParams extends ICreateAssistantParams {
    id: string;
    active: boolean;
    pinned: boolean;
}

export class AssistantService extends BaseService {
    private static instance: AssistantService;

    private constructor() {
        super();
    }

    // Static method to provide global point of access
    public static getInstance(): AssistantService {
        if (!AssistantService.instance) {
            AssistantService.instance = new AssistantService();
        }
        return AssistantService.instance;
    }

    createAssistantAsync = async (params: ICreateAssistantParams): Promise<IAssistant> => {
        const method = 'POST';
        const commandPath = 'assistants';
        const { accessToken, ...body } = params;

        const result = await this.getResponseAsync<AssistantResponse>({ commandPath, method, body }, accessToken);

        return result.assistant;
    };

    getAllAssistantsAsync = async (accessToken: string): Promise<IAssistant[]> => {
        const method = 'GET';
        const commandPath = 'assistants';
        return this.getResponseAsync<IAssistant[]>({ commandPath, method }, accessToken);
    };

    getprivateAssistantsAsync = async (accessToken: string): Promise<IAssistant[]> => {
        const method = 'GET';
        const commandPath = 'assistants/private';
        return this.getResponseAsync<IAssistant[]>({ commandPath, method }, accessToken);
    };

    getGlobalAssistantsAsync = async (accessToken: string): Promise<IAssistant[]> => {
        const method = 'GET';
        const commandPath = 'assistants/global';
        return this.getResponseAsync<IAssistant[]>({ commandPath, method }, accessToken);
    };

    editAssistantAsync = async (params: IEditAssistantParams): Promise<IAssistant> => {
        const { id, accessToken, ...body } = params;
        return this.getResponseAsync<IAssistant>(
            {
                commandPath: `assistants/${id}`,
                method: 'PATCH',
                body,
            },
            accessToken,
        );
    };

    deleteAssistantAsync = async (id: string, accessToken: string): Promise<void> => {
        await this.getResponseAsync(
            {
                commandPath: `assistants/${id}`,
                method: 'DELETE',
            },
            accessToken,
        );
    };

    shareAssistantAsync = async (
        accessToken: string,
        assistantId: string,
        description: string,
    ): Promise<IAssistant> => {
        const method = 'PATCH';
        const commandPath = `assistants/${assistantId}/share`;
        const body = { description };

        return this.getResponseAsync<IAssistant>({ commandPath, method, body }, accessToken);
    };

    unShareAssistantAsync = async (accessToken: string, assistantId: string): Promise<IAssistant> => {
        const method = 'DELETE';
        const commandPath = `assistants/${assistantId}/share`;

        return this.getResponseAsync<IAssistant>({ commandPath, method }, accessToken);
    };
}
