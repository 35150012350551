import React from 'react';

import AnnotationWrapper from './AnnotationWrapper';

const StartPageOverview: React.FC = () => {
    return (
        <>
            <AnnotationWrapper alt="Start page overview screenshot" src="Start_page_overview.jpg">
                <li>
                    <b>&quot;Start new chat&quot;</b> (similar to ChatGPT) is like starting from scratch.
                </li>
                <li>
                    <h3>Your pinned assistants</h3>
                    <ul>
                        <li>Start a chat with your assistants.</li>
                        <li>Create a new assistant.</li>
                    </ul>
                </li>
                <li>
                    <b>Your recent chats</b>
                    <p>Continue chatting in your recent chats.</p>
                </li>
            </AnnotationWrapper>

            <AnnotationWrapper alt="Start page overview screenshot 2" src="Start_page_overview_2.jpg" stepNumber={4}>
                <li>
                    <h3>Sidebar</h3>
                    <ul>
                        <li>Start a new chat from scratch, or with an assistant you have created.</li>
                        <li>Get access to your chat history.</li>
                        <li>Collapse or uncollapse sidebar.</li>
                    </ul>
                </li>
                <li>
                    <h3>Chat settings</h3>
                    <ul>
                        <li>Choose a creativity level, and select a model. And change them whenever you want.</li>
                    </ul>
                </li>
            </AnnotationWrapper>
        </>
    );
};

export default StartPageOverview;
