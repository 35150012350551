import React from 'react';

import AnnotationWrapper from './AnnotationWrapper';

const StartNewChatOverview: React.FC = () => {
    return (
        <AnnotationWrapper alt="Start new chat overview screenshot" src="Start_new_chat_overview.jpg">
            <li>
                <h3>Text field</h3>
                <ul>
                    <li>Enter your questions, instructions, or requests, or click the mic to speak them.</li>
                    <li>Upload documents or files related to your query for more accurate assistance.</li>
                    <li>
                        Don’t rely solely on my output—errors are possible. My knowledge is up to 2023 without real-time
                        internet access.
                    </li>
                </ul>
            </li>
            <li>
                <h3>Uploaded documents tab</h3>
                <ul>
                    <li>
                        You can view your uploaded files in the <b>‘Documents tab’</b>
                    </li>
                </ul>
            </li>
            <li>
                <h3>Auto-delete chat after 60 days</h3>
                <ul>
                    <li>
                        Chats older than 60 days are deleted automatically, with a visual indicator showing the
                        remaining days.
                    </li>
                </ul>
            </li>
        </AnnotationWrapper>
    );
};

export default StartNewChatOverview;
