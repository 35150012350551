import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAssistant } from '../../../libs/models/Assistants';
import { AssistantsState, initialState } from './AssistantsState';
import { EStatus } from '../conversations/ConversationsState';
import { allModels, GPTServiceId } from '../../../components/utils/GPTModels';
import { Constants } from '../../../Constants';

export const assistantsSlice = createSlice({
    name: 'assistants',
    initialState,
    reducers: {
        setAssistantsStatus: (state: AssistantsState, action: PayloadAction<EStatus>) => {
            state.status = action.payload;
        },
        setAssistantsError: (state: AssistantsState, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
        setAssistants: (state: AssistantsState, action: PayloadAction<Record<string, IAssistant>>) => {
            state.assistants = action.payload;
        },
        addAssistant: (state: AssistantsState, action: PayloadAction<IAssistant>) => {
            const newAssistant = action.payload;
            if (!Object.keys(allModels).includes(newAssistant.serviceId)) {
                newAssistant.serviceId = Constants.DEFAULT_MODEL as GPTServiceId;
            }
            state.assistants[newAssistant.id] = newAssistant;
        },
        editAssistantName: (state: AssistantsState, action: PayloadAction<{ id: string; newName: string }>) => {
            const id = action.payload.id;
            const newName = action.payload.newName;
            state.assistants[id].title = newName;
        },
        editAssistant: (state: AssistantsState, action: PayloadAction<IAssistant>) => {
            const editedAssistant = action.payload;
            if (!Object.keys(allModels).includes(editedAssistant.serviceId)) {
                editedAssistant.serviceId = Constants.DEFAULT_MODEL as GPTServiceId;
            }
            state.assistants[editedAssistant.id] = editedAssistant;
        },
        deleteAssistant: (state: AssistantsState, action: PayloadAction<string>) => {
            const { [action.payload]: _, ...remainingAssistants } = state.assistants;
            state.assistants = remainingAssistants;
        },
    },
});

export const {
    setAssistantsStatus,
    setAssistantsError,
    setAssistants,
    addAssistant,
    editAssistantName,
    deleteAssistant,
    editAssistant,
} = assistantsSlice.actions;

export default assistantsSlice.reducer;
