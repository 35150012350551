import React from 'react';
import { Outlet } from 'react-router-dom';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlInput from '@shoelace-style/shoelace/dist/react/input';
import SlCheckbox from '@shoelace-style/shoelace/dist/react/checkbox';
import SlIconButton from '@shoelace-style/shoelace/dist/react/icon-button';

import { useGoPage } from '../../libs/hooks/useRouterNavigate';
import { IAssistant } from '../../libs/models/Assistants';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import AssistantManagement from '../assistant-hub/AssistantManagement';
import { DefaultButton as CreateAssistantButton } from '../global/Buttons';
import AbsentAssistants from './AbsentAssistants';
import Preferences from '../../redux/features/app/Preferences';
import { EStatus } from '../../redux/features/conversations/ConversationsState';
import Spinner from '../shared/Spinner';

import classes from './AssistantHub.module.scss';

const assistantSorterByDate = (a: IAssistant, b: IAssistant) => {
    if (!a.createdOn || !b.createdOn || a.createdOn === b.createdOn) return 0;
    return a.createdOn > b.createdOn ? -1 : 1;
};

const showInfoBoxDelay = 3000;

interface IAssistantsGroups {
    personal: { pinned: string[]; active: string[]; inactive: string[] };
    shared: { pinned: string[]; active: string[]; inactive: string[] };
}

const AssistantHub: React.FC = () => {
    const timerId = React.useRef<NodeJS.Timeout | null>(null);
    const { goCreateAssistant } = useGoPage();
    const {
        preferences: { pinnedAssistants, activeAssistants },
        alerts,
    } = useAppSelector((state: RootState) => state.app);
    const { assistants, status } = useAppSelector((state: RootState) => state.assistants);
    const {
        preferences: { showSideBar, createdByMe, createdByOthers, sharedAssistantFilter },
        activeUserInfo,
    } = useAppSelector((state: RootState) => state.app);

    const [sharedAssistantsIds, setSharedAssistantsIds] = React.useState<string[] | null>(null);
    const [personalAssistantsIds, setPersonalAssistantsIds] = React.useState<string[] | null>(null);

    const [search, setSearch] = React.useState(sharedAssistantFilter);
    const [shouldFilterCreatedByMe, setShouldFilterCreatedByMe] = React.useState(createdByMe);
    const [shouldFilterCreatedByOthers, setShouldFilterCreatedByOthers] = React.useState(createdByOthers);

    const [showInfoBox, setShowInfoBox] = React.useState(false);
    React.useEffect(() => {
        const assistantSharedAlerts = alerts.filter(({ id }) => {
            return id?.startsWith('assistant shared:');
        });
        if (assistantSharedAlerts.length) {
            setShowInfoBox(true);
            timerId.current = setTimeout(() => {
                setShowInfoBox(false);
            }, showInfoBoxDelay);
        }
    }, [alerts]);

    const preferencesInstance = Preferences.getInstance();

    React.useEffect(() => {
        if (status === EStatus.Loading) return;
        const assistantsIds = Object.values(assistants)
            .sort(assistantSorterByDate)
            .map(({ id }) => id);
        const assistantsGroups: IAssistantsGroups = {
            personal: { pinned: [], active: [], inactive: [] },
            shared: { pinned: [], active: [], inactive: [] },
        };
        assistantsIds.forEach((id) => {
            if (!assistants[id]) return;
            const { assistantScope } = assistants[id];
            const level1 = assistantScope == 0 ? 'shared' : 'personal';
            const level2 =
                (!preferencesInstance.isActive(id) && 'inactive') ||
                (preferencesInstance.isPinned(id) && 'pinned') ||
                'active';
            assistantsGroups[level1][level2].push(id);
        });

        const { personal, shared } = assistantsGroups;
        setPersonalAssistantsIds([...personal.pinned, ...personal.active, ...personal.inactive]);
        setSharedAssistantsIds([...shared.pinned, ...shared.active, ...shared.inactive]);
    }, [status, assistants, pinnedAssistants, activeAssistants]);

    return (
        <div
            aria-label="Manage assistants"
            className={`${classes.container} ${showSideBar ? '' : classes['sidebar-hidden']}`}
        >
            <div className={classes.column}>
                <div className={classes['header-level-1']}>
                    <h1 className={classes.title}>Manage assistants</h1>
                    <p className={classes.limitation}>You can pin up to 4 of your favourite assistants.</p>
                </div>

                {showInfoBox && (
                    <div className={classes['info-box']}>
                        <SlIcon name="check" className={classes['info-box-icon']}></SlIcon>
                        <div className={classes['info-box-content']}>
                            <b className={classes['info-box-message']}>Your assistant has been successfully shared!</b>
                            <p className={classes['info-box-explanation']}>It is now available for everyone to use.</p>
                        </div>
                        <SlIconButton
                            name="x-lg"
                            label="Close"
                            className={classes['info-box-close-button']}
                            onClick={() => {
                                setShowInfoBox(false);
                            }}
                        ></SlIconButton>
                    </div>
                )}

                <div aria-label="Assistant hub main view" className={classes.assistants}>
                    <div className={classes['header-level-2']}>
                        <h2 className={classes.title}>Personal assistants</h2>
                        {personalAssistantsIds?.length && (
                            <CreateAssistantButton
                                onClick={goCreateAssistant}
                                className={classes['create-assistant-button']}
                            >
                                <SlIcon slot="prefix" name="plus-circle"></SlIcon>
                                Create assistant
                            </CreateAssistantButton>
                        )}
                    </div>

                    {(!personalAssistantsIds && <Spinner />) ||
                        (personalAssistantsIds?.length && (
                            <div className={classes['personal-assistants']}>
                                {personalAssistantsIds.map((id) => (
                                    <AssistantManagement key={id} assistantId={id} />
                                ))}
                            </div>
                        )) || (
                            <AbsentAssistants>
                                <CreateAssistantButton
                                    onClick={goCreateAssistant}
                                    className={classes['create-assistant-button']}
                                >
                                    <SlIcon slot="prefix" name="plus-circle"></SlIcon>
                                    Create assistant
                                </CreateAssistantButton>
                            </AbsentAssistants>
                        )}
                </div>

                <div aria-label="Shared assistant hub view" className={classes.assistants}>
                    <div className={classes['header-level-2']}>
                        <h2 className={classes.title}>Shared assistants</h2>
                    </div>
                    {(!sharedAssistantsIds && <Spinner />) ||
                        (sharedAssistantsIds?.length && (
                            <div>
                                {/* <span className={classes.subtitle}> */}
                                Itera’s shared assistants for everyday use.
                                <div className={classes.search}>
                                    <SlInput
                                        placeholder="Search"
                                        clearable
                                        className={classes['search-input']}
                                        value={search}
                                        onSlInput={(e) => {
                                            setSearch((e.target as HTMLInputElement).value.toLowerCase());
                                        }}
                                    >
                                        <SlIcon name="search" slot="prefix"></SlIcon>
                                    </SlInput>
                                    <div className={classes['search-filters']}>
                                        <SlCheckbox
                                            checked={shouldFilterCreatedByMe}
                                            onSlChange={() => {
                                                setShouldFilterCreatedByMe(!shouldFilterCreatedByMe);
                                            }}
                                        >
                                            Created by me
                                        </SlCheckbox>
                                        <SlCheckbox
                                            checked={shouldFilterCreatedByOthers}
                                            onSlChange={() => {
                                                setShouldFilterCreatedByOthers(!shouldFilterCreatedByOthers);
                                            }}
                                        >
                                            Created by others
                                        </SlCheckbox>
                                    </div>
                                </div>
                                <div className={classes['personal-assistants']}>
                                    {sharedAssistantsIds
                                        .filter(
                                            (id) =>
                                                (shouldFilterCreatedByMe &&
                                                    assistants[id]?.creatorUserId === activeUserInfo?.id) ||
                                                (shouldFilterCreatedByOthers &&
                                                    assistants[id]?.creatorUserId !== activeUserInfo?.id),
                                        )
                                        .filter((id) => assistants[id]?.title.toLowerCase().includes(search))
                                        .map((id) => (
                                            <AssistantManagement key={id} assistantId={id} isShared />
                                        ))}
                                </div>
                            </div>
                        )) || <AbsentAssistants shared />}
                </div>
            </div>
            <Outlet />
        </div>
    );
};

export default AssistantHub;
