import React from 'react';

import { Constants } from '../../../Constants';

import classes from './HelpAndSupport.module.scss';

const { PRACTICE_AI_TOOLS_URL, FEEDBACK_URL } = Constants;

const HelpAndSupport: React.FC = () => {
    return (
        <div className={classes.container}>
            <div className={classes.card}>
                <div className={classes['text-container']}>
                    <p className={classes.text}>Reach out to us</p>
                </div>
                <div className={classes[`link-container`]}>
                    <div className={classes[`icon-container`]}>
                        <img src="/slack-logo.svg" alt="Slack logo" className={classes.icon} />
                    </div>
                    <a href={PRACTICE_AI_TOOLS_URL} target="_blank" rel="noopener noreferrer" className={classes.link}>
                        #practice-ai-tools
                    </a>
                </div>
            </div>

            <div className={classes.card}>
                <div className={classes[`text-container`]}>
                    <p className={classes.text}>Give us feedback</p>
                </div>
                <div className={classes[`link-container`]}>
                    <div className={classes[`icon-container`]}>
                        <img src="/slack-logo.svg" alt="Slack logo" className={classes.icon} />
                    </div>
                    <a href={FEEDBACK_URL} target="_blank" rel="noopener noreferrer" className={classes.link}>
                        #sapience-feedback
                    </a>
                </div>
            </div>
        </div>
    );
};

export default HelpAndSupport;
