import { Constants } from '../../Constants';

export const onboardingImages = [
    'onboarding/Onboarding stage 01.png',
    'onboarding/Onboarding stage 02.png',
    'onboarding/Onboarding stage 03.png',
];

export const onboardingBodies = [
    <div key="onboarding-slide-1">
        <h2 style={{ fontSize: '32px', fontFamily: 'neue machina', marginBottom: '16px', fontWeight: '500' }}>
            Hi! I&apos;m Sapience
        </h2>
        <p style={{ fontSize: '16px', fontFamily: 'ibm plex sans', lineHeight: '24px' }}>
            I&apos;m ready for your questions, instructions and requests. Let&apos;s do this.
        </p>
    </div>,
    <div key="onboarding-slide-2">
        <h2 style={{ fontSize: '32px', fontFamily: 'neue machina', marginBottom: '16px', fontWeight: '500' }}>
            Personal assistants at hand
        </h2>
        <p style={{ fontSize: '16px', fontFamily: 'ibm plex sans', lineHeight: '24px' }}>
            I help you build your own assistants that can keep track of everyday tasks and take care of all the small
            chores that steal your time.
        </p>
    </div>,
    <div key="onboarding-slide-3">
        <h2 style={{ fontSize: '32px', fontFamily: 'neue machina', marginBottom: '16px', fontWeight: '500' }}>
            Don&apos;t worry.
            <br />
            It&apos;s safe.
        </h2>
        <p style={{ fontSize: '16px', fontFamily: 'ibm plex sans', lineHeight: '24px' }}>
            I store your activity securely in Itera&apos;s databases. To ensure compliance, I automatically delete chat
            messages and uploaded documents older than {Constants.CHAT_EXPIRATION_DAYS} days.
        </p>
    </div>,
];

export const AssistantTutorialImages = new Array(4).fill('onboarding/AI assistant.png');

export const AssistantTutorialBodies = [
    <div
        key="onboarding-slide-1"
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'flex-start',
            justifyContent: 'center',
        }}
    >
        <h2 style={{ fontSize: '32px', fontFamily: 'neue machina', marginBottom: '16px', fontWeight: '500' }}>
            Assistant, you say?
        </h2>
        <p style={{ fontSize: '16px', fontFamily: 'ibm plex sans', lineHeight: '24px' }}>
            An assistant is trained to be a specialist in specific tasks through your instructions.
            <br />
            <br />
            When you chat with the assistant, you build on the instructions you have given previously.
        </p>
    </div>,
    <div
        key="onboarding-slide-2"
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'flex-start',
            justifyContent: 'center',
        }}
    >
        <h2 style={{ fontSize: '32px', fontFamily: 'neue machina', marginBottom: '16px', fontWeight: '500' }}>
            What does this mean?
        </h2>
        <p style={{ fontSize: '16px', fontFamily: 'ibm plex sans', lineHeight: '24px' }}>
            You can create assistants that specialise in a wide range of tasks based on your needs.
            <br />
            <br />
            Some examples include assistants for generating text in a specific style, providing coding assistance,
            creating user stories, offering product recommendations, and much more.
            <br />
            <br />
            The possibilities are vast, and you have the flexibility to customise assistants to excel in the areas that
            matter most to you.
        </p>
    </div>,
    <div
        key="onboarding-slide-3"
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'flex-start',
            justifyContent: 'center',
        }}
    >
        <h2 style={{ fontSize: '32px', fontFamily: 'neue machina', marginBottom: '16px', fontWeight: '500' }}>
            An example
        </h2>
        <p style={{ fontSize: '16px', fontFamily: 'ibm plex sans', lineHeight: '24px' }}>
            Let&apos;s say you are working on a website for a bank customer and want to write product descriptions.
            <br />
            <br />
            With an assistant, you can give it instructions to generate consistent and engaging descriptions in the
            desired tone of voice.
            <br />
            <br />
            Every time you start a new chat with the assistant, it already knows what tone of voice to use.
        </p>
    </div>,
    <div
        key="onboarding-slide-4"
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'flex-start',
            justifyContent: 'center',
        }}
    >
        <h2 style={{ fontSize: '32px', fontFamily: 'neue machina', marginBottom: '16px', fontWeight: '500' }}>
            Ready?
        </h2>
        <p style={{ fontSize: '16px', fontFamily: 'ibm plex sans', lineHeight: '24px' }}>
            Creating an assistant can save you significant time in the long run, especially when it comes to automating
            repetitive tasks.
            <br />
            <br />
            I&apos;m here to guide you through the necessary steps.
        </p>
    </div>,
];
