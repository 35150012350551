import React from 'react';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';

import { IAssistant } from '../../libs/models/Assistants';
import { RootState } from '../../redux/app/store';
import { useAppSelector, useAssistantByIdSelector } from '../../redux/app/hooks';
import AssistantActions from './AssistantActions';
import Preferences from '../../redux/features/app/Preferences';
import { formatDate, isEditedAfterShare, isRecently } from './helper';

import styles from './AssistantManagement.module.scss';
import tooltipstyles from '../global/tooltip.module.scss';

const AssistantManagement: React.FC<{
    assistantId: string;
    isShared?: boolean;
}> = ({ assistantId, isShared }) => {
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const assistant: IAssistant = useAssistantByIdSelector(assistantId) ?? {};

    const creatorName = assistant?.creatorUserId === activeUserInfo?.id ? 'You' : assistant.creatorDisplayName;

    const preferencesInstance = Preferences.getInstance();
    const isPinned = preferencesInstance.isPinned(assistantId);

    const RecentlyChangedLabel = () => {
        if (!isShared) return null;
        const isRecentlyEdited = isRecently(assistant.lastEditedOn);
        const tooltipText = 'Last edit: ' + formatDate(assistant.lastEditedOn);
        const isRecentlyShared =
            isRecently(assistant.sharedOn) && !isEditedAfterShare(assistant.lastEditedOn, assistant.sharedOn);
        return (
            (isRecentlyShared && <div className={styles['label-recently-shared']}>Recently shared</div>) ||
            (isRecentlyEdited && (
                <SlTooltip className={tooltipstyles['sapience-tooltip']} content={tooltipText}>
                    <div className={styles['label-recently-edited']}>Recently edited</div>
                </SlTooltip>
            )) ||
            null
        );
    };

    if (!assistant.title) return null;

    return (
        <div
            aria-label={`Personal assistant ${assistant.title}`}
            className={styles.assistant}
            style={{ backgroundColor: isPinned ? 'var(--extended-grass-in-bright-light)' : '' }}
        >
            <AssistantActions assistantId={assistantId} RecentlyChangedLabel={RecentlyChangedLabel} />
            {isShared && (
                <div className={styles['assistant-description']}>
                    <div>{assistant.description}</div>
                    <div className={styles['assistant-owner']}>
                        <span>Owner:</span>
                        <span className={styles['assistant-owner-name']}>{creatorName}</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AssistantManagement;
