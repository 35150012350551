import { Icon } from '@iconify/react';
import SlButton from '@shoelace-style/shoelace/dist/react/button';

import { useGoPage } from '../../libs/hooks/useRouterNavigate';
import useWindowSize from '../../libs/hooks/useWindowSize';
import { useAppSelector, useAppStatusSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { AppStatus } from '../../redux/features/app/AppState';
import Preferences from '../../redux/features/app/Preferences';
import Iterai from './Iterai';
import { UserSettingsMenu } from './UserSettingsMenu';

import { Constants } from '../../Constants';
import classes from './Header.module.scss';

const HeaderComponent = () => {
    const { goFAQPage } = useGoPage();
    const windowSize = useWindowSize();
    const appState = useAppStatusSelector();
    const { showSideBar } = useAppSelector((state: RootState) => state.app.preferences);
    const openFeedbackWindow = () => window.open(Constants.FEEDBACK_URL);
    const { isAssistantEditRoute, isAssistantCreateRoute } = useGoPage();

    const isSmallScreen = windowSize.width <= 1024;
    const isSmallScreenAndSidebarOpened = isSmallScreen && showSideBar;

    const closeSidebar = () => {
        Preferences.getInstance().setShowSidebar(false);
    };
    const openSidebar = () => {
        Preferences.getInstance().setShowSidebar(true);
    };

    return (
        <div className={`${classes.header} ${isSmallScreenAndSidebarOpened ? classes['bg-white'] : ''}`}>
            <Iterai />
            {appState > AppStatus.SettingUserInfo && (
                <div className={classes.navbar}>
                    {!isSmallScreen && (
                        <div className={classes['havbar-nav']}>
                            <div className={classes['navbar-item']} onClick={openFeedbackWindow}>
                                <img src="/feedback.svg" style={{ marginRight: '6px' }} />
                                Feedback
                            </div>
                            <div className={classes['navbar-item']} onClick={goFAQPage}>
                                <img src="/tips.svg" style={{ marginRight: '6px' }} />
                                FAQ
                            </div>
                        </div>
                    )}
                    {!isSmallScreenAndSidebarOpened && <UserSettingsMenu />}
                    {!isAssistantCreateRoute && !isAssistantEditRoute && isSmallScreen && !showSideBar && (
                        <SlButton variant="text" onClick={openSidebar} className={classes['button-show-sidebar']}>
                            <Icon icon={'system-uicons:menu-hamburger'} width="32px" height="32px" color="white" />
                        </SlButton>
                    )}
                    {!isAssistantCreateRoute && !isAssistantEditRoute && isSmallScreen && showSideBar && (
                        <SlButton variant="text" onClick={closeSidebar} className={classes['button-hide-sidebar']}>
                            <Icon icon={'lets-icons:close-round-light'} width="32px" height="32px" />
                        </SlButton>
                    )}
                </div>
            )}
        </div>
    );
};

export default HeaderComponent;
