import React from 'react';

import AnnotationWrapper from './AnnotationWrapper';

const HowToCreateAssistant: React.FC = () => {
    return (
        <>
            <AnnotationWrapper
                alt="How to create an assistant overview screenshot 1"
                src="How_create_assistant_overview_1.jpg"
            >
                <h3>What is assistant?</h3>
                <p>
                    An assistant follows your instructions to complete tasks and remembers them to build on your initial
                    setup during chats.
                </p>
                <br />
                <li>
                    <h3>Create assistant</h3>
                    <ul>
                        <li>
                            To create your own assistant, go to the
                            <b> ‘Sidebar’</b>, select
                            <b> ‘Manage’</b>, and click
                            <b> ‘Create Assistant’ </b>
                            to start the setup process.
                        </li>
                    </ul>
                </li>
            </AnnotationWrapper>
            <AnnotationWrapper
                alt="How to create an assistant overview screenshot 2"
                src="How_create_assistant_overview_2.jpg"
                stepNumber={2}
            >
                <li>
                    <h3>Build your assistant: Step 1 - Create a prompt</h3>
                    <b>Step 1 - Create a prompt</b>
                    <ul>
                        <li>Share context with Sapience about your desired assistant&apos;s role.</li>
                        <li>
                            Click <b>“Generate prompt”</b> to get a suggested prompt.
                        </li>
                        <li>Edit, rewrite, or add details to customize the prompt.</li>
                    </ul>
                    <br />
                    <p>
                        <b>Tip: </b>
                        <span>
                            Click ‘Generate prompt’ multiple times for new suggestions. You can also fine-tune your
                            setup anytime. A prompt tells the assistant what you want it to do.
                        </span>
                    </p>
                </li>
            </AnnotationWrapper>
            <AnnotationWrapper
                alt="How to create an assistant overview screenshot 3"
                src="How_create_assistant_overview_3.jpg"
                stepNumber={3}
            >
                <li>
                    <h3>Build your assistant: Step 2 - Settings</h3>
                    <ul>
                        <li>Name your assistant, choose a creativity level, and select a model.</li>
                    </ul>
                </li>
            </AnnotationWrapper>
            <AnnotationWrapper
                alt="How to create an assistant overview screenshot 4"
                src="How_create_assistant_overview_4.jpg"
                stepNumber={4}
            >
                <li>
                    <h3>
                        Build your assistant: Step 2 - Upload documents
                        <br />
                        (Upload document to Assistant feature is Coming Soon)
                    </h3>
                    To provide your assistant with more context, you can upload a document by clicking or dragging and
                    dropping your files.
                </li>
            </AnnotationWrapper>
            <AnnotationWrapper
                alt="How to create an assistant overview screenshot 5"
                src="How_create_assistant_overview_5.jpg"
                stepNumber={5}
            >
                <li>
                    <h3>Assistants location</h3>
                    <p>
                        The assistants you create will appear in your <b>Sidebar</b> and on the
                        <b>“Manage assistants”</b> page in <b>Personal assistant</b> block.
                    </p>
                    <p>You can always return to adjust and refine them to fit your changing needs.</p>
                </li>
            </AnnotationWrapper>
        </>
    );
};

export default HowToCreateAssistant;
