import { useLocation, useNavigate, useResolvedPath } from 'react-router-dom';

import { Constants } from '../../Constants';

const { routes } = Constants;

export const useGoPage = () => {
    const { pathname } = useLocation();
    const { pathname: callerPath } = useResolvedPath(`../`);

    const navigate = useNavigate();

    const goStartPage = () => {
        navigate(routes.start);
    };

    const handleBackClick = () => {
        navigate(-1); // Navigate to the previous page
    };

    const goFAQPage = () => {
        navigate(routes.faq);
    };

    const goCreateAssistant = () => {
        navigate(routes.createAssistant);
    };
    const goEditAssistant = (assistantId: string) => {
        navigate(`${routes.editAssistant}/${assistantId}`);
    };
    const goManageAssistant = () => {
        navigate(routes.manageAssistant);
    };

    const goChat = (id: string) => {
        navigate(`${routes.chat}/${id}`);
    };

    const goBackToCaller = () => {
        navigate(callerPath);
    };

    const isAssistantCreateRoute = pathname.includes(Constants.routes.createAssistant);
    const isAssistantEditRoute = pathname.includes(Constants.routes.editAssistant);
    const isStartPageRoute = pathname === Constants.routes.start;

    return {
        goStartPage,
        handleBackClick,
        goFAQPage,
        goCreateAssistant,
        goEditAssistant,
        goManageAssistant,
        goChat,
        goBackToCaller,
        isAssistantCreateRoute,
        isAssistantEditRoute,
        isStartPageRoute,
    };
};
