import { FC, ReactNode } from 'react';

import classes from './AnnotationWrapper.module.scss';

const AnnotationWrapper: FC<{ children: ReactNode; src: string; alt: string; stepNumber?: number }> = ({
    children,
    src,
    alt,
    stepNumber = 1,
}) => (
    <div className={classes.column}>
        <ol className={`${classes.annotation} ${classes[`start-from-${stepNumber}`]}`}>{children}</ol>
        <div className={classes['annotation-screenshot']}>
            <img src={`/faq/${src}`} alt={alt} />
        </div>
    </div>
);

export default AnnotationWrapper;
